<template lang="pug">
div(:class="{'candidate-table-searching': $route.query.search}" data-test="candidate-list")
    app-table(
      v-model="activeItems"
      :headers="headers"
      :expanded.sync="expanded"
      single-expand=true
      :list="list"
      item-key="ID"
      show-select
      hide-default-footer
      multi-sort=false
      :sort-by.sync="sortModel.by"
      :sort-desc.sync="sortModel.desc"
      @update:sort-desc="updateSorting"
      :server-items-length="list.length"
      :single-select="false"
      :loading="loading"
      :mobile-breakpoint='NaN'
      hide-default-header
      class="app-table elevation-1 v-data-table theme--light"
    )
        template(v-slot:top)
          .pr-4.pl-4.pt-2.pb-2.d-flex.justify-end(v-if="isActiveLabels")
            portal-target(name="candidate-labels-list")
          v-divider(v-if="isActiveLabels")

          portal(to="candidates-filters")
            .actions-list
              refresh-button(@click="loadList" v-show="!activeItemsLength").mr-2.pb-1.pt-1
              div(v-if="activeItemsLength").app-table__selected-items-title {{ activeItemsLength}} items selected
              select-all(v-if="showSelectAll" @change="toggleSelectAllItems" :activeItemsCount="activeItemsLength" :itemsToSelectCount="pagination.count").mx-2.my-1
              v-spacer
              emailBulk(:activeItems="activeItems").mr-2.pb-1.pt-1
              bulk-assign-labels(:disabled="!activeItemsLength" :activeItems="activeItems").mr-2.pb-1.pt-1
              bulk-assign-labels(btnText="Unassign labels" mode="remove" :disabled="!activeItemsLength" :activeItems="activeItems").mr-2.pb-1.pt-1
              removeRequests(:disabled="!activeItemsLength" :activeItems="activeItems").mr-2.pb-1.pt-1
              try-to-reserve(:activeItems="activeItems" :disabled="!activeItemsLength").mr-2.pb-1.pt-1
              changeCourseBulk(
                :activeItems="activeItems"
                :disabled="!activeItemsLength"
              ).mr-2.pb-1.pt-1
              handleFailedCandidatesProvider(v-slot="handleFailed").pb-1.pt-1
                span
                  v-dialog(:value="handleFailed.showing" @input="handleFailed.toggle" scrollable)
                    handleFailedBody(
                      :list="handleFailed.failedCandidates"
                      :error="handleFailed.error"
                      :totalSendCount="handleFailed.totalSendCount"
                      @input="handleFailed.toggle"
                    )
                  span.d-flex
                    exchange(
                      :disabled="!activeItemsLength"
                      :activeItems="activeItems"
                      @send="handleFailed.check($event, activeItemsLength)"
                      title="Suggest exchange"
                      isExchange
                    ).mr-2

                    quickReserve(
                      :activeItems="activeItems"
                      :disabled="!activeItemsLength"
                      :city="+$route.query.city"
                      title="Quick exchange"
                      @send="handleFailed.check($event, activeItemsLength)"
                      isExchange
                    ).mr-2
              sync(:disabled="!activeItemsLength" :activeItems="activeItems").mr-2.pb-1.pt-1
              copy(:activeItems="activeItems" :disabled="!activeItemsLength" :list="list" multiple).mr-2.pt-1.pb-1
              candidate-color(:activeItems="activeItems" :disabled="!activeItemsLength" :page="COLOR_PAGE").mr-2.pt-1.pb-1
          v-divider

        template(v-slot:header="headerRow")
          header-row(:headerRow="headerRow")

        template(v-slot:item="{item, select, isSelected, index, expand, isExpanded}")
          candidate-row(
            :item="item"
            :isSelected="isSelected"
            :select="select"
            :index="index"
            :expand="expand"
            :isExpanded="isExpanded"
            :pagination="pagination"
            :primaryPagination="primaryPagination"
            :key="index"
            :labels="labels"
            :activeItems="activeItems"
            @selectedWithShiftkey="extendedSelection"
            @onGoToEdit="goToEdit"
            @click:payments="paymentsOpen"
          )

        template( v-slot:expanded-item="{ headers, item, expand }")
          td(:colspan="headers.length").pl-0.pr-0.app-table__expand
            candidate-item(
              :ID="item.ID"
              @close="hideExpandBy(expand)"
              ref="expand"
              :key='item.ID'
              @saved="updateTableDataBy(item, $event)"
            )

        template(v-slot:footer="{options}")
            .pagination(v-if="pagination")
                load-more-button(:loading="loading" :pagination="pagination" @loadMore="onLoadMoreClick")
                div.pagination__actions
                    v-pagination(
                        @input="paginationChange"
                        :disabled="loading"
                        :length="pagination.totalPages"
                        v-model="pagination.page"
                        :total-visible="8")

    payments-provider(:ID="1" ref="payments")
      template(v-slot:default="props")
        span
          payments-cancel-provider(
              :ID="props.ID"
              :payments="props.logs"
              @end="props.create"
              @canceled="props.updateCanceledLog"
            )
              template(v-slot:default="{showing, cancelPayment, send, skip, toggle, showWhenHasCreatedPayment, processing}")
                span
                  v-dialog(:value="props.showing" :max-width="800" scrollable @input="props.toggle")
                    payments(
                      v-if="props.showing"
                      :items="props.list"
                      :itemsLoading="props.listLoading"
                      :formErrors="props.formErrors"
                      :editorData="props.editorData"
                      :processing="props.processing"
                      :log="props.logs"
                      @click:custom="props.setNewEditor"
                      @close="props.toggle"
                      @submit="showWhenHasCreatedPayment"
                    )
                      template(v-slot:log-actions="{ paymentLog, isPaid }")
                        v-btn(:disabled="isPaid" small icon color="error" :loading="processing" @click="cancelPayment(paymentLog.ID)")
                          v-icon mdi-delete

                  confirmation-dialog(
                    v-model="showing"
                    @okBtnClicked="skip"
                    @failBtnClicked="toggle"
                    title="Candidate has payment"
                    descr="Are you sure you want to create new payment and cancel the old one?"
                    okText="ok"
                    failText="cancel"
                  )
    payments-wrapper(ref="payments")
    portal-target(name="candidate-create")
</template>

<script>
import SortModel from "./core/models/candidatesSortModel"
import { FILTERS_ARGS, CANDIDATE_PAGE, CANDIDATES_HEADERS } from './core/candidates-const'
import copyMixin from "@/mixins/copy.mixin"
import itemModel from "./core/candidates-reservation-model"
import paginationMixin from '@/mixins/pagination.mixin'
import PaginationModel from "@/models/paginationModel"
import CandidateBroadcast from "./core/models/candidateBroadcast"
import CandidatePageMixin from "./core/candidates-page.mixin"
import errorsMixin from '@/mixins/errors.mixin'

export default {
  name: 'CandidatesReservedExchangedPage',

  mixins: [copyMixin, paginationMixin, CandidatePageMixin, errorsMixin],

  data: () => ({
    headers: [
      {text: '', value: 'edit', class: 'app-table__h_small cell-btns', sortable: false},
      {text: '№', value: 'number', class: 'app-table__h_small cell-index ', align: 'left', sortable: false},
      {text: 'Color', value: 'color', align: 'left', class: 'nowrap cell-placed-paid', sortable: true},
      {text: 'Placed/Paid', value: 'placed', align: 'left', class: 'nowrap cell-placed-paid', sortable: false},
      {text: 'Name', value: 'candidateName', align: 'left', class: 'cell-name', sortable: true},
      {text: 'CDN', align: 'left', sortable: false, value: 'candidateNumber', class: 'nowrap cell-number'},
      {text: 'DOB/Phone', value: 'candidateBirthday', class: 'nowrap cell-bday', align: 'center'},
      {text: 'CBR', value: 'accountUsername', align: 'left', class: 'cell-cbr', sortable: true},
      {text: 'App ID', value: 'appId', align: 'center', class: 'cell-appid text-center', sortable: false},
      {text: 'Payment', value: 'payment', sortable: false, class: 'cell-payment'},
      {text: 'Reserved Info', value: 'reserved', align: 'center', class: 'cell-reserved', sortable: true},
      {text: 'Exchange count', value: 'exchange_count', align: 'center', class: 'cell-exchange-count', sortable: false},
      {text: 'License type', value: 'licenseType', align: 'left', class: 'cell-product', sortable: false},
      {text: 'Product', value: 'productName', align: 'left', class: 'cell-product', sortable: true},
      {text: 'Language', value: 'language', align: 'left', class: 'cell-product', sortable: true},
      {text: 'Status', value: 'status', align: 'left', class: 'cell-status'},
      {text: 'Canceled', value: 'canceled', align: 'left', class: 'cell-canceled'},
      {text: 'Last error', value: 'lastError', align: 'center', class: 'nowrap cell-error', sortable: false},
      {text: 'Course type', value: 'course-type', align: 'left', class: 'nowrap cell-course-type', sortable: false},
      {text: 'Package', value: 'package', align: 'left', class: 'nowrap cell-package', sortable: false},
      {text: 'Online from now', value: 'online_access_days_left', align: 'left', class: 'cell-duration', sortable: true},
      {text: 'Wiki from now ', value: 'wiki_access_days_left', align: 'left', class: 'cell-duration', sortable: true},
      {text: '', value: 'bov', align: 'center', class: 'nowrap cell-email', sortable: false},
      {text: 'Confirmed Courses', value: 'mainDate', align: 'left', class: 'cell-dates', sortable: false},
      {text: 'Reservation dates', value: 'requests', align: 'left', class: 'cell-reservation', sortable: false},
      {text: 'Note', value: 'note', align: 'center', sortable: false, class: 'cell-note'},
    ],
  }),

  inject: ['svc'],

  methods: {
    async loadList({more = false}) {
      this._loadList({more, page: CANDIDATE_PAGE.RESERVED_EXCHANGED})
    },
  },

  components: {
    appTable: () => import('@/components/global/GroupedTable.vue'),
    candidateRow: () => import('./components/candidates/CandidateRowRDED.vue'),
    candidateItem: () => import('./components/item/CandidateItem.vue'),
    copy: () => import('./components/candidates/CandidatesCopy.vue'),
    candidateColor: () => import('./components/CandidateColor.vue'),
    headerRow: () => import('./components/candidates/CandidatesHeaders.vue'),
    bulkAssignLabels: () => import('./components/candidates/CandidatesBulkAssignLabels.vue'),
    PaymentsProvider: () => import('./components/payments/CanidatesPaymentsProvider.vue'),
    Payments: () => import('./components/payments/CandidatesPayments.vue'),
    paymentsCancelProvider: () => import('./components/payments/CandidatesPaymentCancelProvider.vue'),
    emailBulk: () => import('./components/CandidatesEmail.vue'),
    handleFailedCandidatesProvider: () => import('./components/CandidatesHandleFailedProvider'),
    handleFailedBody: () => import('./components/CandidatesHandleFailedBody.vue'),
    selectAll: () => import('./components/CandidateSelectAll.vue'),
    refreshButton: () => import('./components/CandidatesRefreshButton.vue'),
    changeCourseBulk: () => import('@/app/admin/modules/candidates/components/CandidatesChangeCourseBulk.vue'),
    removeRequests: () => import('./components/CandidatesRemoveExamRequest.vue'),
    tryToReserve: () => import('./components/CandidatesTryToReserve.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
    exchange: () => import('./components/CandidatesExchange.vue'),
    paymentsWrapper: () => import('./components/payments/CandidatePaymentWrapper.vue'),
    sync: () => import('./components/CandidatesSync.vue'),
    quickReserve: () => import('./components/CandidatesQuickReserve.vue'),
    loadMoreButton: () => import('@/components/widget/LoadMoreButton.vue'),
  },

  beforeRouteLeave(to, from, next) {
    this.broadcast.close()
    next()
  },

  provide: function () {
    return {
      markAsProcessed: this.markAsProcessed,
      loadList: this.loadList,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../../src/app/admin/modules/candidates/assets/candidates.scss";
</style>
